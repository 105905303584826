.dropdown-content {
  position: absolute;
  color: black;
  top: 33px;
  right: -10px;
  min-width: px;
  background: #fff;
  padding: 2px 0px;
  border-radius: 0px;
  box-shadow: 0 0 0.6rem 0 rgb(0 0 0 / 30%);
  z-index: 10000;
}

.navbar-dropdown {
  padding: 0;
  margin: 0;
  list-style: none;
}

ul.navbar-dropdown li {
  padding: 5px 10px;
  font-weight: 500;
  letter-spacing: 0.03rem;
}

.dropdown-links {
  text-decoration: none;
  color: black;
}

.dropdown-links:hover {
  color: #007bff !important;
  cursor: pointer;
}

.clicked {
  display: none;
}
